import React from "react"
import { Link } from "gatsby"
import MarketoForm from "./marketing-forms/MarketoForm"

const thankYouMessage = "Thanks for contacting us! We'll be in touch soon!"

const ContactUsForm = () => {
  return (
    <div
      css={theme => ({ marginBottom: theme.space[12], maxWidth: 800 })}
      id="contact-us"
    >
      <h3>Get in Touch</h3>
      <MarketoForm
        formId={1002}
        messageOnSubmit={thankYouMessage}
        wrapperCss={{ maxWidth: `none` }}
      />
      <p css={theme => ({ marginTop: theme.space[5] })}>
        Need help with Gatsby? Visit our{" "}
        <Link to="/support">support portal here!</Link>
      </p>
    </div>
  )
}

export default ContactUsForm
