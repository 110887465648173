import React from "react"
import PropTypes from "prop-types"

import Title from "./title.js"
import Subtitle from "./subtitle.js"
import Container from "../../components/container"
import HeroContainer from "../../components/containers/hero"
import LinkToParentPage from "../../components/link-to-parent-page"

class Hero extends React.Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
  }

  render() {
    const { page } = this.props

    return (
      <HeroContainer
        css={{
          display: `flex`,
          width: `100%`,
        }}
      >
        <Container>
          {page.parentPage && <LinkToParentPage contentfulPage={page} />}
          <Title>{page.title}</Title>
          {page.subtitle && <Subtitle>{page.subtitle.subtitle}</Subtitle>}
        </Container>
      </HeroContainer>
    )
  }
}

export default Hero
