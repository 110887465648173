import React, { Fragment } from "react"
import PropTypes from "prop-types"

import Default from "./default"
import Duotone from "./duotone"

class Hero extends React.Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    image: PropTypes.object,
    layout: PropTypes.object.isRequired,
  }

  render() {
    const { page, image, layout } = this.props

    return (
      <Fragment>
        {
          {
            Default: <Default page={page} layout={layout} />,
            Duotone: <Duotone page={page} image={image} layout={layout} />,
          }[layout.heroLayout]
        }
      </Fragment>
    )
  }
}

export default Hero
